import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import NotAvailable from '../../components/NotAvailable';
import { Spinner } from '../../components/Spinner';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { useEnv } from '../../context/env-context';
import { Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import { useTranslation } from 'react-i18next';

export function Signup() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { apiOriginConsumer } = useEnv();

  const [validating, setValidating] = useState(false);
  const [available, setAvailable] = useState({});

  const { setSessionData, gotoError } = useSessionData();

  const { i18n, t } = useTranslation();

  const getAvailability = async () => {
    const URL = `${process.env.REACT_APP_API_ORIGIN_ORCHESTRATOR}/status`;

    try {
      const response = await axios.get(URL, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          language: i18n.resolvedLanguage,
        },
      });

      setAvailable(response.data);

      return response.data;
    } catch (error) {
      if (error.response.status <= 500 && error.response.status >= 300) {
        setAvailable({ status: 'down' });

        return { status: 'down' };
      }

      setAvailable({ status: 'down', message1: t('errors.connection_error') });

      return { status: 'down', message1: t('errors.connection_error') };
    }
  };

  const preValidate = async signup_token => {
    // pre validate
    const config = {
      url: `${apiOriginConsumer}/pre_validate`,
      method: 'POST',
      data: JSON.stringify({ signupToken: signup_token, language: i18n.resolvedLanguage }),
    };

    try {
      const data = await makeUnauthorizedRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);
      setValidating(false);

      return { ok: false };
    }
  };

  const validate = async () => {
    const pv = await preValidate(params.signup_token);

    if (!pv.ok) {
      gotoError(pv.reason);

      return false;
    }
    setValidating(false);
    setSessionData('sessionId', pv.sessionId);
    setSessionData('employeeIdMode', pv.employeeIdMode);
    setSessionData('genericSignup', pv.genericSignup);

    return true;
  };

  const init = async () => {
    const av = await getAvailability();

    if (av.status !== 'down') {
      setValidating(true);
      if (await validate()) {
        const decoded = jwt_decode(params.signup_token);

        setSessionData('signupToken', params.signup_token);
        setSessionData(
          'consumer',
          JSON.stringify({
            firstName: decoded.prospectFirstName || (location.state && location.state.firstName),
            lastName: decoded.prospectLastName || (location.state && location.state.lastName),
            email: decoded.prospectEmail || (location.state && location.state.email),
            phone: decoded.prospectPhone,
            employeeId: decoded.prospectEmployeeId,
            addressLine1: decoded.prospectAddressLine1,
            addressLine2: decoded.prospectAddressLine2,
            city: decoded.prospectCity,
            state: decoded.prospectState,
            zip: decoded.prospectZip,
            dateOfBirth: decoded.prospectDateOfBirth,
            aggregatorId: decoded.aggId,
            tokenType: decoded.tokenType,
          }),
        );
        setSessionData(
          'aggregator',
          JSON.stringify({
            id: decoded.aggId,
            name: decoded.aggName,
          }),
        );
        setSessionData('signupV2', location.pathname.includes('/signup_v2/'));
        navigate('/signup/page1', { replace: true });
      }
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (validating) {
    return (
      <Container className="bo-mobile">
        <Row className="mt-5 justify-content-center align-items-center text-center mx-0">
          <Col>
            <Spinner visible text={t('pages.signup.validate_signup')} contentColor="#FFFFFF" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (available.status === 'down') {
    return <NotAvailable available={available} />;
  }

  return (
    <Container className="bo-mobile">
      <Row className="mt-5 align-items-center text-center mx-0">
        <Col>
          <Spinner visible text={t('common.hold_on')} contentColor="#FFFFFF" />
        </Col>
      </Row>
    </Container>
  );
}

export default Signup;
