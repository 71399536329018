import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Input } from 'reactstrap';

function AggreatorForm({
  isGenericSignup,
  consumer,
  formErrors,
  handleChange,
  renderError,
  aggregatorName,
  isSignupV2,
}) {
  const { t } = useTranslation();

  if (isGenericSignup) {
    return (
      <Row className="mb-2 mx-3">
        <Col className="mt-4 text-center">
          <p className="bo-text">{t('pages.signup_details_first.aggregator_form.description')}</p>
          <FormGroup>
            <Input
              className="bo-input"
              type="text"
              name="tempAggregatorName"
              id="tempAggregatorName"
              placeholder={t(
                'pages.signup_details_first.aggregator_form.employer_name_placeholder',
              )}
              value={consumer.tempAggregatorName ? consumer.tempAggregatorName : ''}
              noValidate
              onChange={e => handleChange(e)}
            />
            {'tempAggregatorName' in formErrors ? renderError(formErrors.tempAggregatorName) : ''}
          </FormGroup>
        </Col>
      </Row>
    );
  }

  if (isSignupV2) {
    return (
      <Row className="mb-2 mx-3">
        <Col className="mt-4 text-center">
          <p className="bo-text">{t('pages.signup_details_first.to_signup')}</p>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mb-2 mx-3">
      <Col className="mt-4 text-center">
        <p className="bo-text">{t('pages.signup_details_first.aggregator_name')}</p>
        {aggregatorName && <p className="bo-larger-x2">{aggregatorName}</p>}
      </Col>
    </Row>
  );
}

export default AggreatorForm;
