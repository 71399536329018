import { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useEnv } from '../../context/env-context';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from '../../utils/makeRequest';
import { useSessionData } from '../../hooks/useSessionData';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/Spinner';

function ShortUrl() {
  const params = useParams();
  const navigate = useNavigate();
  const { apiOriginAggregator } = useEnv();
  const { gotoError } = useSessionData();

  const { t } = useTranslation();

  const getDestinationUrl = async () => {
    const config = {
      url: `${apiOriginAggregator}/destination_url`,
      method: 'GET',
      params: { id: params.short_url },
    };

    try {
      const { destination } = await makeRequest(config);

      if (destination && destination.indexOf('/signup/') !== -1) {
        navigate(destination.substring(destination.indexOf('/signup/')));
      } else if (destination && destination.indexOf('/signup_v2/') !== -1) {
        navigate(destination.substring(destination.indexOf('/signup_v2/')));
      } else {
        gotoError(t('pages.short_url.url_error'), `/${params.short_url}`);
      }
    } catch (error) {
      gotoError(error.message, `/${params.short_url}`);
    }
  };

  useEffect(() => {
    getDestinationUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="bo-mobile">
      <Row className="mt-5 align-items-center text-center mx-0">
        <Col>
          <Spinner visible contentColor="#FFFFFF" />
        </Col>
      </Row>
    </Container>
  );
}

export default ShortUrl;
