import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Button, Col, Container, Row } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useEnv } from '../../context/env-context';
import { useSessionData } from '../../hooks/useSessionData';
import { useNavigate } from 'react-router-dom';
import { makeRequest, makeUnauthorizedRequest } from '../../utils/makeRequest';
import { Spinner } from '../../components/Spinner';

function DeleteAccount() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [spinner, setSpinner] = useState(false);
  const [pp, setPp] = useState(null);

  const { sessionData, gotoError } = useSessionData();
  const { apiOriginConsumer, apiOriginOrchestrator } = useEnv();

  const getSetting = async key => {
    setSpinner(true);

    const config = {
      url: `${apiOriginOrchestrator}/get_setting`,
      method: 'GET',
      params: { key },
    };

    try {
      const data = await makeUnauthorizedRequest(config);

      setPp(data.value);
    } catch (error) {
      gotoError(error.message, '/signup/login?callbackUrl=/delete_account');
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (!sessionData().signinToken) {
      navigate('/signup/login?callbackUrl=/delete_account');
    } else {
      getSetting('privacy_policy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitRequest = async () => {
    setSpinner(true);

    const config = {
      token: sessionData().signinToken,
      url: `${apiOriginConsumer}/unsubscribe_ticket`,
      method: 'POST',
    };

    try {
      await makeRequest(config);

      navigate('/delete_account_success');
    } catch (error) {
      gotoError(error, '/signup/login?callbackUrl=/delete_account');
    } finally {
      setSpinner(false);
    }
  };

  if (!sessionData().signinToken || !sessionData().consumer) {
    return null;
  }

  // test comment
  return (
    <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
      <Container className="bo-mobile">
        <Header />
        <div className="bo-rounded-container mt-5">
          <Row className="mt-4 mx-3">
            <Col className="text-start">
              <p className="bo-text bo-larger">{t('pages.delete_account.delete_your_account')}</p>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col className="text-start">
              <p className="bo-text">
                <Trans t={t} i18nKey="pages.delete_account.to_begin_the_process">
                  To begin the process of deleting your Bridgeover account, please click the
                  <strong> Submit Delete Request</strong> button below. Our customer service team
                  will then reach out to you to finalize the deletion.
                </Trans>
              </p>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.delete_account.no_access')}</p>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col className="text-start">
              <p className="bo-text">
                <Trans t={t} i18nKey="pages.delete_account.data_will_be_deleted">
                  All your data will be deleted, other than personal information that is required,
                  in order to comply with legal obligations. You will receive a confirmation once
                  your data is deleted. Specifically, we keep records of any advances previously
                  extended to you, as detailed in our
                  <a href={pp}> privacy policy</a>.
                </Trans>
              </p>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.delete_account.bridgepoints_expire')}</p>
            </Col>
          </Row>
          <Row className="mt-5 mb-4 mx-3">
            <Col className="d-flex justify-content-center">
              <Button className="bo-button bo-w-250" onClick={submitRequest}>
                {t('pages.delete_account.submit_request')}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </Spinner>
  );
}

export default DeleteAccount;
