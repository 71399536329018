import { Trans, useTranslation } from 'react-i18next';
import logo from '../assets/nameLogo.png';
import { Row, Col } from 'reactstrap';
import LanguageSwitcher from './LanguageSwitcher';

function Header({ welcomeText, aggregatorName, personalDetails, languageSwitcher }) {
  const { t } = useTranslation();

  return (
    <div>
      {languageSwitcher && <LanguageSwitcher />}
      <Row className="mt-3 mx-0">
        <Col className="text-center mt-4">
          <img alt="logo" src={logo} className="name-logo" />
        </Col>
      </Row>
      {welcomeText === 'default' && (
        <>
          <Row className="mt-2 mx-3 text-center">
            <p className="bo-header text-white">{t('components.enter_details_header.title')}</p>
          </Row>
          <Row className="mb-2 mx-3 text-center">
            <p className="bo-text text-white">
              {t('components.enter_details_header.signup_description')}
            </p>
          </Row>
        </>
      )}
      {welcomeText === 'alternative' && (
        <>
          <Row className="mt-2 mx-3">
            <p className="text-center bo-header fw-normal text-white pb-0 mb-0">
              {t('components.enter_details_header.title')}
            </p>
            <p className="text-center bo-header fw-normal text-white">
              {t('components.enter_details_header.free_benefit')}
            </p>
          </Row>
          {aggregatorName && (
            <Row className="mb-2 mx-3">
              <Col className="text-center">
                <p className="bo-header text-white">{aggregatorName}</p>
              </Col>
            </Row>
          )}
          <Row className="mb-3 mx-2">
            <p className="bo-text-bold text-white mb-0">
              {t('components.enter_details_header.with_bridgeover')}
            </p>
            <p className="bo-text text-white mb-0">
              • {t('components.enter_details_header.benefits.get_cash')}
            </p>
            <p className="bo-text text-white mb-0">
              • {t('components.enter_details_header.benefits.choose_payback')}
            </p>
            <p className="bo-text text-white mb-0">
              • {t('components.enter_details_header.benefits.no_cost')}
            </p>
            <p className="bo-text text-white mb-0">
              • {t('components.enter_details_header.benefits.saving_tips')}
            </p>
          </Row>
          <Row className="mb-3 mx-2">
            <p className="bo-text-bold text-white mb-0">
              {t('components.enter_details_header.why_signup')}
            </p>
            <p className="bo-text text-white mb-0">
              • {t('components.enter_details_header.reasons.the_sooner')}
            </p>
            <p className="bo-text text-white mb-0">
              • {t('components.enter_details_header.reasons.longer_to_pay')}
            </p>
          </Row>
          <Row className="mt-3 mb-2 mx-2">
            <p className="text-center bo-text-bold text-white">
              {t('components.enter_details_header.free_and_easy')}
            </p>
          </Row>
        </>
      )}
      {personalDetails && (
        <Row className="mt-3 mb-2 mx-3">
          <p className="text-center bo-text text-white">
            <Trans i18nKey="components.enter_details_header.personal_details" t={t}>
              In order to verify your identity we need a few more personal details. Please complete
              the details below, accept the service <strong>terms and conditions</strong>
              and click &ldquo;Continue&rdquo;
            </Trans>
          </p>
        </Row>
      )}
    </div>
  );
}

export default Header;
